<template>
    <div class="LokerList">
        <div class="relative">
            <dropdown 
                :dtar="company"
                backgroundColor="bg-primary"
                textColor="text-grays-50 font-bold"
                position="absolute"
                class="top-5 left-0 z-10"
            />
        </div>
        
        <DataTable
            title=""
            :columns="tableHead"
            :rows="lokerFilter"
            :clickable="true"
            :sortable="true"
            :exactSearch="true"
            :searchable="true"
            :paginate="true"
            :exportable="false"
            :printable="false"
            v-on:row-click="onRowClick"
            class="list-none"
        />
				
    </div>
</template>

<script>

import "material-design-icons-iconfont/dist/material-design-icons.css";
import DataTable from "vue-materialize-datatable";
import dropdown from "@/components/karir/Dropdown";
import moment from 'moment'
import { mapState } from 'vuex';
export default {
    components: {
        DataTable,
        dropdown
    },
    data() {
        return {
            tableColumns1: [
                {
                    label: "Posisi",
                    field: "posisi",
                    numeric: false,
                    html: false
                },
                {
                    label: "Lokasi",
                    field: "lokasi",
                    numeric: false,
                    html: false
                },
                {
                    label: "Pengalaman",
                    field: "pengalaman",
                    numeric: false,
                    html: false
                },
                {
                    label: "Batas Waktu",
                    field: "bataswaktu",
                    numeric: false,
                    html: false
                }
            ],
            tableColumns2: [
                {
                    label: "Position",
                    field: "posisi",
                    numeric: false,
                    html: false
                },
                {
                    label: "Location",
                    field: "lokasi",
                    numeric: false,
                    html: false
                },
                {
                    label: "Experience",
                    field: "pengalaman",
                    numeric: false,
                    html: false
                },
                {
                    label: "Closing date",
                    field: "bataswaktu",
                    numeric: false,
                    html: false
                }
            ],
            tableRows1: [
                {
                    posisi: "PT DHARMA LOGISTICS PROFESSIONAL PROGRAM",
                    lokasi: "DKI JAKARTA",
                    pengalaman: "5 Tahun",
                    bataswaktu: "21 Februari 2021"
                },
                {
                    posisi: "HSE SECTION HEAD",
                    lokasi: "DKI JAKARTA",
                    pengalaman: "2 Tahun",
                    bataswaktu: "26 Februari 2021"
                },
                {
                    posisi: "GROUP LEADER - PLANT",
                    lokasi: "MEDAN",
                    pengalaman: "2 Tahun",
                    bataswaktu: "14 Juni 2021"
                },
                {
                    posisi: "HSE SECTION HEAD",
                    lokasi: "DKI JAKARTA",
                    pengalaman: "5 Tahun",
                    bataswaktu: "21 Februari 2021"
                },
                {
                    posisi: "KOORDINATOR HRGA, IT AND HSE",
                    lokasi: "ACEH",
                    pengalaman: "10 Tahun",
                    bataswaktu: "01 Januari 2022"
                },
                {
                    posisi: "RADAR & RADIO OFFICER",
                    lokasi: "JAWA TIMUR",
                    pengalaman: "10 Tahhun",
                    bataswaktu: "N/A"
                },
                {
                    posisi: "CONTRACTS SUPERINTENDENT",
                    lokasi: "PADANG",
                    pengalaman: "51 Tahun",
                    bataswaktu: "21 Februari 2021"
                },
                {
                    posisi: "GROUP LEADER - PRODUCTION",
                    lokasi: "PAPUA",
                    pengalaman: "1 Tahun",
                    bataswaktu: "10 Juli 2021"
                },
                {
                    posisi: "RADAR & RADIO OFFICER",
                    lokasi: "MEDAN",
                    pengalaman: "2 Tahun",
                    bataswaktu: "N/A"
                },
                
                {
                    posisi: "PT DHARMA LOGISTICS PROFESSIONAL PROGRAM",
                    lokasi: "DKI JAKARTA",
                    pengalaman: "5 Tahun",
                    bataswaktu: "21 Februari 2021"
                },
                
                {
                    posisi: "HSE SECTION HEAD",
                    lokasi: "DKI JAKARTA",
                    pengalaman: "5 Tahun",
                    bataswaktu: "21 Februari 2021"
                },
                {
                    posisi: "KOORDINATOR HRGA, IT AND HSE",
                    lokasi: "ACEH",
                    pengalaman: "10 Tahun",
                    bataswaktu: "01 Januari 2022"
                },
                {
                    posisi: "RADAR & RADIO OFFICER",
                    lokasi: "JAWA TIMUR",
                    pengalaman: "10 Tahhun",
                    bataswaktu: "N/A"
                },
                
                {
                    posisi: "GROUP LEADER - PRODUCTION",
                    lokasi: "PAPUA",
                    pengalaman: "1 Tahun",
                    bataswaktu: "10 Juli 2021"
                },
                {
                    posisi: "RADAR & RADIO OFFICER",
                    lokasi: "MEDAN",
                    pengalaman: "2 Tahun",
                    bataswaktu: "N/A"
                },
                {
                    posisi: "RISK MANAGEMENT SUPERINTENDENT",
                    lokasi: "PAPUA",
                    pengalaman: "7",
                    bataswaktu: "15 Februari 2021"
                }
            ],
        }
    },

    computed: {
        ...mapState({
            company: state => state.karir.company ? state.karir.company.data :'',
			listLoker: state => state.karir.listLoker ? state.karir.listLoker.data :'',
			lang: state => state.home.lang ? state.home.lang :'id',
            selectCompanyId:  state => state.karir.selectCompanyId ? state.karir.selectCompanyId :'',
        }),
        lokerFilter(){
            const data = this.listLoker.careers.filter((item) => {
                return item.company_id == this.selectCompanyId
            })

            if(this.lang == 'id'){
                let list = []

                for (let i = 0; i < data.length; i++) {

                    const mentah = {
                        posisi:data[i].title,
                        lokasi:data[i].location,
                        pengalaman:data[i].experience + ' Tahun',
                        bataswaktu:moment(data[i].expired_at).locale("de").format('DD MMMM YYYY'),
                        id:data[i].id
                    }
                        
                    list.push({ ...mentah});   
                }

                return list
            }else{
                let list = []

                for (let i = 0; i < data.length; i++) {

                    const mentah = {
                        posisi:data[i].title_eng,
                        lokasi:data[i].location,
                        pengalaman:data[i].experience + ' Years',
                        bataswaktu:moment(data[i].expired_at).format('DD MMMM YYYY'),
                        id:data[i].id
                    }
                        
                    list.push({ ...mentah});   
                }

            return list
            }

            
        },
        tableHead(){
            if(this.lang == 'id'){
                return this.tableColumns1
            }else{
                return this.tableColumns2
            }
        }

    },
    methods: {
        async onRowClick(row){
            try {
                await this.$store.dispatch('karir/getViewLoker',row.id)
                this.$router.push(
                    { 
                        name: "View"
                    }
                )
			
            } catch (error) {
                console.log(error)
            }


            
        }
    },
}
</script>

<style lang="scss">
    .LokerList{
        .material-table{
            position: relative;
            padding-top: 72px !important;

            .table-header{
                border: none;
                width: 78px;
                position: absolute;
                right: -11px;
                top: 27px;
                z-index: 10;
                height: 48px;
            }
        }

        table {
            @apply w-full mt-5;

            thead{
                @apply bg-secondary;

                th{
                    @apply text-grays-50 text-base font-bold
                }
                .sorting-desc, .sorting-asc{
                    @apply text-primary
                }
            }

           
        }

        #search-input-container{
            @apply absolute  my-2 border-none w-80 -right-3 top-5 mr-0;
            

            input{
                @apply  shadow rounded   p-3 w-full pr-10 outline-none;
                
            }

            #search-input{
                border:1px solid #212121 !important;
                height: 42px !important;
            }
        }

        
    }
</style>


